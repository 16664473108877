import { useTranslation } from 'react-i18next'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { EMAIL, WHATSAPP } from '@/constants/contact'
import { track } from '@/utils/analytics'
import { EmailIcon, WhatsappIcon } from '@/assets/icons'

const AdditionalQuestions = ({ className }: { className?: string }) => {
  const { t } = useTranslation()
  const {
    state: { experience },
  } = useGotuuriContext()
  const iconClass = 'w-[22px] h-[22px] flex-shrink-0'
  const aClass = 'text-base font-normal text-black ml-3 hover:text-primary'
  const divClass = 'flex items-center mb-2'
  return (
    <div
      className={`border-lightGray border-1 rounded-2xl px-6 py-4 ${className}`}
    >
      <p className="text-2xl mb-3">{t('experience.additionalQuestions')}</p>
      <p className="text-base font-normal text-black mb-3">
        {t('experience.additionalDetails')}
      </p>
      <div className={divClass}>
        <WhatsappIcon className={iconClass} />
        <a
          target="_blank"
          href={`${WHATSAPP}?text=${t('experience.supportMessage2', {
            expName: experience?.name.replaceAll(' ', '%20'),
          })}`}
          onClick={() =>
            track('Contact Form Go To Whatsapp', {
              experienceName: experience?.name,
              experienceId: experience?.id,
              screen: 'Experience',
            })
          }
          className={aClass}
        >
          {t('experience.whatsapp')}
        </a>
      </div>
      <div className="flex items-center">
        <EmailIcon className={iconClass} />
        <a
          target="_blank"
          href={EMAIL}
          onClick={() =>
            track('Contact Form Go To Email', {
              experienceName: experience?.name,
              experienceId: experience?.id,
              screen: 'Experience',
            })
          }
          className={aClass}
        >
          {t('experience.email')}
        </a>
      </div>
    </div>
  )
}

export default AdditionalQuestions
