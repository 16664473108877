import { COUNTRY_ID } from '@/constants/cookie'
import { normalizeItem } from './normalizeUrl'
import { ExperienceType } from '@/constants/generalCarousel'

export const updateCountryStorage = (countryId: string) => {
  if (countryId === 'all') {
    localStorage.removeItem(COUNTRY_ID)
  } else {
    localStorage.setItem(COUNTRY_ID, countryId)
  }
}

export const updateLocation = (
  countryId: string,
  countries: any,
  navigate: any
) => {
  if (countryId === 'all') {
    navigate('/')
  } else {
    const country = countries?.data?.find(
      (country: { id: string; name: string }) => country.id === countryId
    )

    navigate(
      normalizeItem({
        name: country?.name,
        id: country?.id,
        type: ExperienceType.COUNTRY,
      })
    )
  }
}
