import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from '@nextui-org/react'
import { CalendarRow } from '../CalendarRow'
import SelectHourRow from '../SelectHourRow/SelectHourRow'
import { ParticipantsRow } from '../ParticipantsRow'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { useTranslation } from 'react-i18next'
import { ArrowBlackImg } from '@/components/ArrowBlackImg'
import { UniqueDatesRow } from '../UniqueDatesRow'
import { amountFormatter } from '@/utils/amountFormatter'
import { AdditionalProductsRow } from '../AdditionalProductsRow'
import DisclosureIcon from '@/assets/svgs/difficulty.svg'

interface MobileBookModalProps {
  bookingDate: string
  handleBookingDate: (newBookingDate) => void
  handleBookButton: () => void
  isMobileOpen: boolean
  setIsMobileOpen: (isOpen: boolean) => void
  setTotalPrice: (price: number) => void
  handleSelectHour: (schedule: any, index: number) => void
  selectedHourIndex: any
  handleTotalParticipants: (participants) => void
  totalPrice: number
  totalParticipantQuantity: number
  bookingHour: string
  setBookingHour: (bookingHour) => void
  additionalAmount: number
  setAdditionalAmount: (number) => void
  totalProducts: Array<any>
  setTotalProducts: (totalProducts) => void
  rangeDiscount: number
}
const MobileBookModal = ({
  totalProducts,
  setTotalProducts,
  additionalAmount,
  setAdditionalAmount,
  bookingDate,
  handleBookingDate,
  handleBookButton,
  isMobileOpen,
  setIsMobileOpen,
  setTotalPrice,
  handleSelectHour,
  selectedHourIndex,
  handleTotalParticipants,
  totalPrice,
  totalParticipantQuantity,
  bookingHour,
  setBookingHour,
  rangeDiscount,
}: MobileBookModalProps) => {
  const {
    state: { experience, uniqueDates, bookingData, availableHours },
  } = useGotuuriContext()
  const { t } = useTranslation()

  return (
    <>
      <Modal
        // hideCloseButton
        scrollBehavior={'inside'}
        // size={'full'}
        size="2xl"
        placement="bottom"
        onOpenChange={setIsMobileOpen}
        className="mx-0 h-[85vh]"
        isOpen={isMobileOpen}
      >
        <ModalContent>
          {() => (
            <>
              {/* <ModalHeader className="my-2">
                <Button
                  isIconOnly
                  variant='light'
                  className="w-12 h-12 min-w-unit-0 rounded-full shadow-lg"
                  onPress={() => {
                    setIsMobileOpen(false)
                    setTotalPrice(
                      experience.price_package ?? experience.price_adults
                    )
                  }}
                >
                  <ArrowBlackImg className={'rotate-90 w-5 h-5'} />
                </Button>
              </ModalHeader> */}
              <ModalBody className="scrollbar-hide overscroll-none mt-12">
                <h2 className="text-4xl">{t('bookingCard.booking')}</h2>
                <h3 className="text-xl my-4">{t('bookingCard.dateAndHour')}</h3>
                <div className="px-2">
                  {!!!uniqueDates ? (
                    <CalendarRow handleBookingDate={handleBookingDate} />
                  ) : uniqueDates?.dates.length < 1 ? (
                    <CalendarRow
                      handleBookingDate={handleBookingDate}
                      isDateRange={true}
                      rangeMinDate={uniqueDates?.start_date}
                      rangeMaxDate={uniqueDates?.end_date}
                    />
                  ) : (
                    <UniqueDatesRow
                      uniqueDates={uniqueDates}
                      handleBookingDate={handleBookingDate}
                    />
                  )}
                  <SelectHourRow
                    bookingDate={bookingDate}
                    handleSelectHour={handleSelectHour}
                    bookingHour={bookingHour}
                    setBookingHour={setBookingHour}
                  />
                </div>

                <h3 className="text-xl mb-4">
                  {t('bookingCard.participants')}
                </h3>
                <div className="px-2">
                  <ParticipantsRow
                    selectedHourIndex={selectedHourIndex}
                    handleTotalParticipants={handleTotalParticipants}
                  />
                  {!!experience?.additional_products.length ? (
                    <AdditionalProductsRow
                      additionalProducts={experience?.additional_products ?? []}
                      setAdditionalAmount={setAdditionalAmount}
                      totalProducts={totalProducts}
                      setTotalProducts={setTotalProducts}
                      bookingData={bookingData}
                    />
                  ) : null}
                </div>
                {experience?.price_discount_groups?.length > 0 && (
                  <div className="flex flex-row gap-2 content-center mb-4">
                    <div className="mt-0">
                      <img
                        src={DisclosureIcon}
                        width={18}
                        alt="disclosure icon"
                      />
                    </div>
                    <p className="text-[12px] text-[#616161]">
                      {t('bookingCard.priceDiscountGroupsInfo')}
                    </p>
                  </div>
                )}
                <div className="mb-32"></div>
                <section className="flex flex-row h-24 bg-white fixed w-full bottom-0 inset-x-0 items-center justify-between shadow-inner z-[99]">
                  <div className="flex flex-col ml-8">
                    <p className=" text-xs text-black">
                      {experience?.price_package
                        ? t('bookingCard.perGroup')
                        : t('bookingCard.perPerson', {
                            count: totalParticipantQuantity,
                          })}
                    </p>
                    <h2 className="text-3xl text-black">
                      {amountFormatter(totalPrice + additionalAmount)}
                    </h2>
                    {/* <span className="text-xs text-gray-300 absolute left-16">
                      {!!rangeDiscount && `(-${rangeDiscount}%)`}
                    </span> */}
                  </div>
                  <Button
                    onClick={() => {
                      handleBookButton()
                    }}
                    isDisabled={
                      !!!bookingHour ||
                      !!!bookingDate ||
                      totalParticipantQuantity < experience?.min_persons
                    }
                    className={`!bg-primary text-white rounded-3xl text-base px-6 mr-4 h-12 ml-4`}
                  >
                    <p className="truncate">{t('bookingCard.next')}</p>
                  </Button>
                </section>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
export default MobileBookModal
