import { ArrowBlackImg } from '@/components/ArrowBlackImg'
import { Layout } from '@/components/Layout'
import { Button, Input } from '@nextui-org/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import redeemImg from '@/assets/images/giftcard/redeem.png'
import { SubmitHandler, useForm } from 'react-hook-form'
import { namePatternRegex } from '@/constants/regex'
import arrowBlack from '@/assets/svgs/arrowBlack.svg'
import { Link } from 'react-router-dom'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import ModalUi from '@/components/Modal/ModalUi'
import { track } from '@/utils/analytics'

const RedeemPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    hooks: { redeemGiftCard, getBalance },
    state: { user, loadingRedeem },
  } = useGotuuriContext()

  const inputStyles = {
    input: ['text-base', 'placeholder:text-lightGray', 'px-3'],
    inputWrapper: ['bg-mediumGray', 'shadow-none', 'h-12', 'rounded-2xl'],
  }

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    values: {
      code: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    track('Redeem Gift Card View')
  }, [])

  const onSubmit: SubmitHandler<any> = async ({ code }) => {
    const body = {
      code,
      customer: user.id,
    }
    await redeemGiftCard(body, setVisible, setValue)
    getBalance(user)
  }
  const [visible, setVisible] = useState(false)
  return (
    <Layout>
      <ModalUi
        open={visible}
        setVisible={setVisible}
        footer={
          <>
            <Link
              to={'/profile/credit-giftcard/balance'}
              className="!bg-black text-white rounded-full text-base flex items-center px-10 h-12"
            >
              {t('redeem.checkBalance')}
            </Link>
          </>
        }
      >
        <>
          <p className="text-black text-center text-2xl my-8">
            {t('redeem.redeemSuccess')}
          </p>
        </>
      </ModalUi>
      <div className="mt-12 pb-8 mb-0 md:mb-20">
        <div className="flex gap-16 flex-col md:flex-row hidden md:block">
          <div className="md:w-12/12 mb-4 md:mb-0">
            <Button
              isIconOnly
              className="w-12 h-12 min-w-unit-0 rounded-full shadow-lg my-12"
              onPress={() => {
                navigate('/profile/credit-giftcard')
              }}
            >
              <ArrowBlackImg />
            </Button>
          </div>
        </div>
        <div className="flex gap-10 flex-col md:flex-row">
          <div className="w-12/12 md:w-7/12">
            <div className="flex gap-6 mb-7 md:hidden">
              <Link className="text-[10px]" to={'/profile'}>
                {t('profile.profile')}
              </Link>
              <img
                className="rotate-270"
                src={arrowBlack}
                alt="arrow black svg"
              />
              <Link className="text-[10px]" to={'/profile/credit-giftcard'}>
                {t('profile.creditAndGiftCard').slice(0, 17) + '...'}
              </Link>
              <img
                className="rotate-270"
                src={arrowBlack}
                alt="arrow black svg"
              />
              <p className="text-[10px] text-primary">
                {t('redeem.title')}
              </p>
            </div>
            <h4 className="grid text-4xl mb-8 ">{t('redeem.title')}</h4>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-8"
            >
              <div className="flex justify-between gap-4 md:gap-8">
                <div className="flex flex-col gap-2 w-full">
                  <p>{t('redeem.form.codeLabel')}:</p>
                  <Input
                    isRequired
                    {...register(`code`, {
                      required: t('signUpForm.errors.required'),
                      pattern: {
                        message: t('signUpForm.errors.invalidFirstName'),
                        value: namePatternRegex,
                      },
                    })}
                    //onChange={handleReceiverUserNameChange}
                    //type="firstName"
                    classNames={inputStyles}
                    placeholder={'XXXXX'}
                    disableAnimation
                  />
                  <p className="text-red-400 text-base font-light">
                    {errors.code?.message.toString()}
                  </p>
                </div>
              </div>
              <div className="flex flex-col-reverse md:flex-col">
                <p className="text-[10px] md:mb-10">{t('redeem.info')}</p>
                <Button
                  type="submit"
                  isLoading={loadingRedeem}
                  className="rounded-full !bg-black text-white w-2/4 mb-10 md:mb-0"
                >
                  {t('redeem.form.submitButton')}
                </Button>
              </div>
            </form>
          </div>
          <div className="w-5/12 hidden md:block">
            <img src={redeemImg} alt='redeem img' />
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default RedeemPage
