import {
  useKeenSlider,
  KeenSliderPlugin,
  KeenSliderInstance,
} from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import 'react-photo-view/dist/react-photo-view.css'
import { MutableRefObject, useState } from 'react'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import { ExperienceShareFavorite } from '@/components/ExperiencePage'

function ThumbnailPlugin(
  mainRef: MutableRefObject<KeenSliderInstance | null>
): KeenSliderPlugin {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove('active')
      })
    }
    function addActive(idx: number) {
      slider.slides[idx].classList.add('active')
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener('click', () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx)
        })
      })
    }

    slider.on('created', () => {
      if (!mainRef.current) return
      addActive(slider.track.details.rel)
      addClickEvents()
      mainRef.current.on('animationStarted', (main) => {
        removeActive()
        const next = main.animator.targetIdx || 0
        addActive(main.track.absToRel(next))
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next))
      })
    })
  }
}

const WheelControls: KeenSliderPlugin = (slider) => {
  let touchTimeout: ReturnType<typeof setTimeout>
  let position: {
    x: number
    y: number
  }
  let wheelActive: boolean

  function dispatch(e: WheelEvent, name: string) {
    position.x -= e.deltaX
    position.y -= e.deltaY
    slider.container.dispatchEvent(
      new CustomEvent(name, {
        detail: {
          x: position.x,
          y: position.y,
        },
      })
    )
  }

  function wheelStart(e: WheelEvent) {
    position = {
      x: e.pageX,
      y: e.pageY,
    }
    dispatch(e, 'ksDragStart')
  }

  function wheel(e: WheelEvent) {
    dispatch(e, 'ksDrag')
  }

  function wheelEnd(e: WheelEvent) {
    dispatch(e, 'ksDragEnd')
  }

  function eventWheel(e: WheelEvent) {
    e.preventDefault()
    if (!wheelActive) {
      wheelStart(e)
      wheelActive = true
    }
    wheel(e)
    clearTimeout(touchTimeout)
    touchTimeout = setTimeout(() => {
      wheelActive = false
      wheelEnd(e)
    }, 50)
  }

  slider.on('created', () => {
    slider.container.addEventListener('wheel', eventWheel, {
      passive: false,
    })
  })
}

const ExperiencePhotosCarousel = ({
  photos,
  experience,
}: {
  photos: string[]
  experience
}) => {
  const PhotosArray =
    photos.length < 4
      ? Array.from({ length: 4 }, (_, i) => photos[i % photos.length])
      : photos

  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    loop: true,
    slides: { perView: 1, spacing: 10 },
    initial: 0,
    created() {
      setLoaded(true)
    },
  })

  const [thumbnailRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      vertical: true,
      mode: 'free-snap',
      loop: true,
      slides: {
        perView: 4,
        spacing: 10,
      },
    },
    [ThumbnailPlugin(instanceRef), WheelControls]
  )

  return (
    <PhotoProvider maskOpacity={0.5}>
      <div className="flex flex-row-reverse gap-2 h-[412px] md:h-[36vh] lg:h-[52vh] relative">
        <div ref={sliderRef} className="keen-slider !w-full md:!w-4/5">
          {PhotosArray.map((image, index) => {
            return (
              <div
                key={index}
                className={`keen-slider__slide h-[412px] md:h-[36vh] lg:h-[52vh] cursor-pointer pb-3 md:px-2`}
              >
                <PhotoView src={image}>
                  <img
                    className="object-cover w-full h-full md:rounded-2xl"
                    src={image}
                    alt={`${experience.name} small image ${index}`}
                  />
                </PhotoView>
              </div>
            )
          })}
        </div>
        <div
          ref={thumbnailRef}
          className="keen-slider thumbnail !w-1/5 !hidden md:!block -mt-0.5"
        >
          {PhotosArray.map((image, index) => {
            return (
              <div
                key={index}
                className={`keen-slider__slide h-full cursor-pointer ${
                  !loaded && 'my-2'
                }`}
              >
                <img
                  className="object-cover rounded-xl !h-full !w-full"
                  src={image}
                  alt={`${experience.name} image ${index}`}
                />
              </div>
            )
          })}
        </div>
        <ExperienceShareFavorite className="md:hidden flex gap-2 absolute top-4 right-4" />
      </div>
    </PhotoProvider>
  )
}

export default ExperiencePhotosCarousel
