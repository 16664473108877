import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface MapProps {
  readOnly: boolean
  initialPosition?: { lat: number; lng: number }
  setPositionGlobal?: (position) => void
  setValue?: any
  showMap?: boolean
}

const CustomMap = ({
  readOnly,
  initialPosition,
  setPositionGlobal,
  setValue,
  showMap = true,
}: MapProps) => {
  const { t } = useTranslation()
  const {
    state: { bookingData },
  } = useGotuuriContext()

  const [positionError, setPositionError] = useState(false)
  const [position, setPosition] = useState(initialPosition)
  const [scriptLoaded, setScriptLoaded] = useState(false)

  useEffect(() => {
    if (!scriptLoaded && typeof window.google !== 'undefined') {
      setScriptLoaded(true)
    }
  }, [scriptLoaded])

  const validateCountry = (result, from) => {
    let iso_code = bookingData.experience.country.iso_code
    let iso_codes = result.address_components.map((item) => item.short_name)
    if (iso_codes.includes(iso_code)) {
      return true
    } else {
      setValue('reference', '', { shouldValidate: true })
      return false
    }
  }

  const getGeocode = (find) => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode(find, (results, status) => {
        if (status === 'OK' && results[0]) {
          resolve(results)
        } else {
          console.log(
            'Error al realizar la solicitud de geocodificación inversa:',
            status
          )
          reject(null)
        }
      })
    })
  }

  useEffect(() => {
    if (typeof window.google !== 'undefined') {
      const geocoder = new window.google.maps.Geocoder()
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: initialPosition,
        zoom: 12,
        clickableIcons: false,
        mapTypeControl: false,
        streetViewControl: false,
      })

      const marker = new window.google.maps.Marker({
        position,
        map,
      })

      // Inicializa el buscador de lugares
      const input = document.getElementById('pac-input') as HTMLInputElement
      if (!!input) {
        const searchBox = new window.google.maps.places.SearchBox(input)

        map.addListener('bounds_changed', () => {
          searchBox.setBounds(map.getBounds())
        })

        // Agrega un listener para el evento 'places_changed'
        searchBox.addListener('places_changed', () => {
          const places = searchBox.getPlaces()

          if (places.length == 0) {
            return
          }
          let parent_place = places[0]
          let place_id = places[0]['place_id']
          getGeocode({ placeId: place_id }).then((result: any) => {
            let place = result[0]
            if (validateCountry(place, 'places_changed')) {
              setValue(
                'reference',
                `${parent_place.name} ${parent_place.vicinity ? parent_place.formatted_address : ''}`,
                { shouldValidate: true }
              )
              const newPosition = {
                lat: parent_place.geometry.location.lat(),
                lng: parent_place.geometry.location.lng(),
              }
              marker.setPosition(newPosition)
              map.setCenter(newPosition)
              setPositionError(false)
            } else {
              setPositionError(true)
            }
          })
        })

        input.addEventListener('keydown', (event) => {
          if (event.key === 'Enter') {
            event.preventDefault()
          }
        })
      }
      if (initialPosition != position) {
        marker.setPosition(initialPosition)
      }

      if (readOnly) {
        marker.setPosition(initialPosition)
      }

      map.addListener('click', (event) => {
        if (!readOnly) {
          geocoder.geocode({ location: event.latLng }, (results, status) => {
            if (status === 'OK') {
              if (results[0]) {
                setValue('reference', results[0].formatted_address, {
                  shouldValidate: true,
                })

                if (validateCountry(results[0], 'click')) {
                  const lat = event.latLng.lat()
                  const lng = event.latLng.lng()
                  const newPosition = { lat, lng }
                  setPosition(newPosition)
                  marker.setPosition(newPosition)
                  setPositionGlobal(newPosition)
                  setPositionError(false)
                } else {
                  setPositionError(true)
                }
              } else {
                let info =
                  'No se encontraron resultados de geocodificación inversa.'
                console.log(info)
              }
            } else {
              const lat = event.latLng.lat()
              const lng = event.latLng.lng()
              const newPosition = { lat, lng }
              setPosition(newPosition)
              marker.setPosition(newPosition)
              setPositionGlobal(newPosition)
              setPositionError(false)
              console.log(
                'Error al realizar la solicitud de geocodificación inversa:',
                status
              )
              //setPositionError(true)
            }
          })
        }
      })
    }
  }, [readOnly, scriptLoaded])

  return (
    <div className="w-full h-full">
      <div
        id="map"
        className={`w-full h-full ${showMap ? '' : 'hidden'}`}
      ></div>
      {positionError && (
        <p className="text-red-400 text-base font-light">{`${t('map.youMustSelectAValidLocationWithin')}: ${bookingData.experience.country.name}`}</p>
      )}
    </div>
  )
}

export default CustomMap
