import { BannerApps } from '@/components/BannerApps'
import { ExperienceCarousel } from '@/components/Carousels/ExperienceCarousel'
import { GeneralCarousel } from '@/components/Carousels/GeneralCarousel'
import { ExperienceList } from '@/components/ExperienceList'
import { FilterButton } from '@/components/FilterButton'
import HeaderSubBanner from '@/components/HeaderBanner/HeaderSubBanner'
import { Layout } from '@/components/Layout'
import { Loading } from '@/components/Loading'
import { NoFilterResults } from '@/components/NoFilterResults'
import { SearchBar } from '@/components/SearchBar'
import { COUNTRY_ID } from '@/constants/cookie'
import { ExperienceType } from '@/constants/generalCarousel'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { track } from '@/utils/analytics'
import { CleverEvents, googleEvents } from '@/utils/events'
import { getTopDestinationsLocaleString } from '@/utils/generalTitle.Util'
import { getFilterParams } from '@/utils/getFilterParams'
import { useIsMobile } from '@/utils/mobile'
import { getCountryDestinationUrl } from '@/utils/normalizeUrl'
import { Button } from '@nextui-org/react'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

const CountriesPage = () => {
  const {
    hooks: {
      setFavoriteExperience,
      getFilterExperiences,
      getCountryProperties,
      getCarouselExperiences,
      setFilterData,
      getCities,
    },
    state: {
      experiences,
      experiencesProperties,
      loadingExperiences,
      user,
      filterData,
      pagination,
      hasSearchResults,
      country,
      cities,
    },
    state,
  } = useGotuuriContext()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { id } = useParams()
  const { search } = useLocation()
  const params = useMemo(() => new URLSearchParams(search), [search])
  const { urlParams, filtersCount } = getFilterParams(params)
  const navigate = useNavigate()

  const SearchBarCallBack = ({ filterUrl }) => {
    navigate(`?${filterUrl}`)
  }

  useEffect(() => {
    getCountryProperties(id)
    if (experiencesProperties?.id !== id) {
      getCities(id)
      getCarouselExperiences({
        type: 'countries',
        id,
        countryId: localStorage.getItem(COUNTRY_ID),
      })
    }
    loadExperiences({ newSearch: true })
    setFilterData({
      urlParams: urlParams,
      filtersCount:
        filtersCount - (!!urlParams[ExperienceType.COUNTRY] ? 1 : 0),
    })
  }, [id, params])

  const loadExperiences = async ({ newSearch }) => {
    const searchText = urlParams.searchText
    const currentPage = newSearch ? 0 : pagination.currentPage ?? 0

    localStorage.setItem(COUNTRY_ID, id)
    const result = await getFilterExperiences({
      page: currentPage + 1,
      data: {
        ...urlParams,
        country: id,
        ...(!!searchText && { searchText }),
      },
      newSearch: newSearch,
    })
    if (result) {
      googleEvents.CategoryView({
        experiences: result,
        page: currentPage + 1,
        state,
      })
    }
  }

  const bannerImage = experiencesProperties?.banner
  const titleName = experiencesProperties?.name ?? ''
  return (
    <div>
      <HeaderSubBanner
        bannerImage={bannerImage}
        loadingExperiences={loadingExperiences}
      >
        <h1 className="text-4xl text-center md:text-[56px] my-8 h-10 md:h-14">
          {titleName}
        </h1>
        <SearchBar showSubmitButton callBack={SearchBarCallBack} />
      </HeaderSubBanner>
      <Layout
        title={
          titleName &&
          t('header.experienceTitle', {
            title: titleName,
          })
        }
        image={bannerImage}
        description={titleName}
        className="mb-32"
      >
        <div className="flex flex-col ">
          {!!!filterData?.urlParams?.searchText && (
            <>
              <div className="flex justify-between items-center">
                <h2
                  className={`hidden md:block text-2xl md:text-4xl my-6 md:my-12`}
                >
                  {t(getTopDestinationsLocaleString(country?.iso_code))}
                </h2>
                <h2
                  className={`block md:hidden text-2xl md:text-4xl my-6 md:my-12`}
                >
                  {t('home.destinations')}
                </h2>
                {country && (
                  <Link to={getCountryDestinationUrl(country)}>
                    <Button className="!bg-black text-white rounded-full px-6 h-9">
                      {t('home.viewAll')}
                    </Button>
                  </Link>
                )}
              </div>
              <GeneralCarousel
                data={cities}
                eventTrigger={(data) => {
                  CleverEvents.DestinationView({
                    name: data.name,
                    id: data.id,
                    screen: 'Country',
                  })
                }}
                type={ExperienceType.DESTINATIONS}
              />
              <ExperienceCarousel />
            </>
          )}

          <div
            className={`flex justify-between my-6  md:mt-12 ${!hasSearchResults ? 'md:mb-0' : 'md:mb-6'}  items-center`}
          >
            <h2 className="text-xl md:text-3xl ">
              {!loadingExperiences
                ? !!hasSearchResults
                  ? t('home.allExperiences')
                  : t('search.noSearchResults')
                : null}
            </h2>
            <FilterButton
              iconOnly={isMobile}
              pathType={ExperienceType.COUNTRY}
              defaultValue={'country'}
              customFunction={() => {
                track('Filter View', {
                  screen: 'Country',
                })
              }}
            />
          </div>
          {!loadingExperiences ? (
            !!hasSearchResults ? (
              <>
                <ExperienceList
                  experiences={experiences?.data || []}
                  user={user}
                  setFavoriteExperience={setFavoriteExperience}
                />
                {pagination?.nextPage && (
                  <Button
                    radius="full"
                    className="!bg-black text-white w-36 mx-auto flex mt-16 md:mt-24"
                    onClick={() => {
                      loadExperiences({
                        newSearch: false,
                      })
                    }}
                  >
                    {t('destinationPage.viewMore')}
                  </Button>
                )}
              </>
            ) : (
              <NoFilterResults />
            )
          ) : (
            <Loading />
          )}
        </div>
      </Layout>
      {/* <BannerApps /> */}
    </div>
  )
}

export default CountriesPage
