import { get, post } from '@/services/axios/axios'
import {
  setLoadingPaymentAction,
  setPurchasesAction,
  setShowAlert,
  setLoadingRedeemAction,
  setLoadingPaymentGiftCardAction,
  setLoadingExperiencesAction,
  setAuthDataAction,
  setIsProcessingPaymentAction,
  setLoggedInAction,
  setUserDataAction,
} from 'store/actions'
import { ERROR, FAILED, SUCCESS, USD } from '@/constants/common'
import { GotuuriState } from '@/models/gotuuri-state'
import { ActionType } from '@/store/actions/base-action'
import {
  setPaymentBody,
  setPaymentGiftCardBody,
  setPaymentWithOutCreditCardBody,
  setPaymentBodyPaypal,
  setPaymentGiftCardBodyPaypal,
} from '@/utils/setPaymentBody'
import { googleTrack, identifyUser, track } from '@/utils/analytics'
import { LOCAL_STORAGE_GIFTCARD } from '@/constants/localStorage'
import { amountFormatter } from '@/utils/amountFormatter'
import { useFormatDate } from '@/utils/formatDate'
import { AFFILIATE_CODE_NAME, REFERENT_CODE_NAME } from '@/constants/cookie'
import { deleteCookie, getCookieValue } from '@/utils/cookie.Util'
import setPurchaseUserBody from '@/utils/setPurchaseUserBody'
import { mapUserData, saveLocalUserData } from './useHandleUserSession'

interface getPurchases {
  userId: string
  section?: 'upcoming-purchases' | 'past-purchases'
  orderBy?:
    | 'date'
    | 'price'
    | 'name'
    | 'status'
    | 'booking-date'
    | 'correlation-id'
  ascendingOrder?: Boolean
}

export const useHandlePurchases = (
  dispatch: (action: ActionType) => void,
  state: GotuuriState
): any => {
  const getPurchases = async ({
    userId,
    section,
    orderBy,
    ascendingOrder,
  }: getPurchases) => {
    let path = `purchases-by-customer/${userId}?section=${section}`

    if (orderBy) {
      path += `&orderBy=${orderBy}`
    }

    if (ascendingOrder) {
      path += `&ascendingOrder=${ascendingOrder}`
    }

    await get({ path }).then((purchasesData: any) => {
      //fix para que aparezca el precio cuando la compra es con gotuuri credits
      purchasesData = purchasesData.map((purchase) => {
        return {
          ...purchase,
          total_value:
            purchase.total_payment + purchase.wallet_discount > 0
              ? purchase.total_payment + purchase.wallet_discount
              : purchase.total_cost,
        }
      })
      dispatch(setPurchasesAction(purchasesData))
      dispatch(setLoadingExperiencesAction(false))
    })
  }

  const cancelPurchase = async ({ purchaseId, customerId }) => {
    await post({
      path: `edit-purchase/${purchaseId}`,
      data: { status: 6 },
    }).then((response) => {
      dispatch(
        setShowAlert({
          show: true,
          type: ERROR,
          message: 'agendaPage.purchaseButtons.purchaseCanceled',
        })
      )
      getPurchases({ userId: customerId, section: 'upcoming-purchases' })
    })
  }

  const sendReview = async (reviewData) => {
    const response = await post({
      path: `add-review/`,
      data: reviewData,
    })
      .then((response) => {
        getPurchases({ userId: reviewData.customer, section: 'past-purchases' })
        return true
      })
      .catch((e) => {
        dispatch(
          setShowAlert({
            show: true,
            type: ERROR,
            message: 'agendaPage.purchaseButtons.reviewButton.error',
          })
        )
        return false
      })
      .finally(() => {
        reviewData.onClose()
      })

    if (response) reviewData.onClose()
    return true
  }

  const addReview = async (reviewData, getPuchase, onSuccess, onError) => {
    await post({
      path: `add-review/`,
      data: reviewData,
    })
      .then((response) => {
        if (getPuchase) {
          getPurchases({ userId: reviewData.customer })
        }
        if (response) onSuccess()
      })
      .catch((e) => {
        onError()
        dispatch(
          setShowAlert({
            show: true,
            type: ERROR,
            message: 'agendaPage.purchaseButtons.reviewButton.error',
          })
        )
      })
  }

  const processBooking = async (purchaseData, setOpenPaymentSuccess) => {
    const body = purchaseData?.require_creditcard
      ? setPaymentBody(purchaseData, state)
      : setPaymentWithOutCreditCardBody(purchaseData, state)

    const bookingData = state.bookingData
    const experience: any = state.bookingData.experience

    const experiencePrice: number = experience?.is_package
      ? experience?.price_package
      : Object.keys(bookingData?.totalParticipants).reduce(
          (acc, key) =>
            acc +
            experience[`price_${key}`] * bookingData?.totalParticipants[key],
          0
        )

    const creditUsed =
      bookingData?.apply_wallet &&
      amountFormatter(
        state.user?.balance >=
          bookingData.totalPrice + bookingData?.totalParticipantQuantity
          ? bookingData.totalPrice + bookingData?.totalParticipantQuantity
          : state.user?.balance
      )

    const creditBalance = state?.user?.balance > 0 ? 'Available' : 'None'

    dispatch(setLoadingPaymentAction(true))

    try {
      const response = await post({
        path: `purchase-experience`,
        data: body,
      }).then(({ data }: any) => {
        window.localStorage.setItem('localBookingData', JSON.stringify(null))
        setOpenPaymentSuccess(true)

        dispatch(setAuthDataAction(null))

        if (!!body.coupon)
          track('Discount Added', {
            couponName: body.coupon,
          })

        track('Reservation Submit', {
          experienceName: experience?.name,
          experienceId: experience?.id,
          submitResult: SUCCESS,
          creditUsed: creditUsed,
          creditBalance: creditBalance,
          couponName: state.bookingData.coupon?.code,
          discountAmount: amountFormatter(
            experiencePrice - state.bookingData.totalPrice
          ),
          experienceCountry: experience?.country?.name,
          experienceDestination: experience?.city?.name,
          tuuriName: experience.companies[0]?.company_name,
          bookingDate: useFormatDate(bookingData?.bookingDate, 'yyyy-MM-dd'),
          bookingHour: bookingData?.bookingHour,
        })

        googleTrack('ReservationSubmit', {
          ecommerce: {
            transaction_id: data?.correlation_id,
            value:
              bookingData?.totalPrice + bookingData?.totalParticipantQuantity,
            currency: USD,
            payment_type: 'Credit Card',
            tax: 0,
            referent: state?.user?.referent,
            ...(!!bookingData?.coupon?.code && {
              coupon: bookingData?.coupon?.code,
            }),
            experiences: [
              {
                experienceId: experience.id,
                experienceName: experience.name,
                destinationID: experience.city.id,
                destinationName: experience.city.name,
                tuuriId: experience.companies[0]?.id,
                tuuriName: experience.companies[0]?.company_name,
                language: state.locale,
                fee: bookingData.totalParticipantQuantity,
                loggedIn: state.isLoggedIn,
                price: experience?.price_adults,
                discount: experiencePrice - bookingData.totalPrice,
                quantity: bookingData.totalParticipantQuantity,
                adults: bookingData.totalParticipants.adults,
                children: bookingData.totalParticipants.children,
                babies: bookingData.totalParticipants.babies,
              },
            ],
          },
        })
        dispatch(setLoadingPaymentAction(false))
        dispatch(setIsProcessingPaymentAction(false))
        return true
      })
      return response
    } catch (e) {
      let message =
        e?.data?.message?.[0]?.message ??
        e?.data?.message ??
        'payment.paymentError'

      let errorMessage =
        typeof message === 'string' ? message : 'payment.paymentError'

      const CUSTOMER_ERROR = {
        USER_DELETED: 'payment.paymentError',
        USER_BLOCKED: 'payment.userBlocked',
        USER_UNAVAILABLE: 'payment.paymentError',
      }

      if (e?.data?.error_reason in CUSTOMER_ERROR) {
        errorMessage = CUSTOMER_ERROR[e?.data?.error_reason]
      }
      if(errorMessage === 'Declined - One or more fields in the request contains invalid data') errorMessage = "payment.paymentError"

      dispatch(
        setShowAlert({
          show: true,
          type: ERROR,
          message: errorMessage.toString(),
        })
      )

      track('Reservation Submit', {
        experienceName: experience?.name,
        experienceId: experience?.id,
        submitResult: FAILED,
        creditUsed: creditUsed,
        creditBalance: creditBalance,
        couponName: state.bookingData.coupon?.code,
        discountAmount: amountFormatter(
          experiencePrice - state.bookingData.totalPrice
        ),
        experienceCountry: experience?.country?.name,
        experienceDestination: experience?.city?.name,
        tuuriName: experience.companies[0]?.company_name,
        bookingDate: useFormatDate(bookingData?.bookingDate, 'yyyy-MM-dd'),
        bookingHour: bookingData?.bookingHour,
      })
      dispatch(setLoadingPaymentAction(false))
      dispatch(setIsProcessingPaymentAction(false))
      return false
    }
  }

  const processGiftCard = async (purchaseData, setOpenPaymentSuccess) => {
    const body = setPaymentGiftCardBody(purchaseData, state)
    const giftCardData = state.giftCardData
    dispatch(setLoadingPaymentGiftCardAction(true))
    dispatch(setLoadingPaymentAction(true))
    try {
      await post({
        path: `create-gift-card`,
        data: body,
      }).then(({ data }: any) => {
        window.localStorage.setItem(
          LOCAL_STORAGE_GIFTCARD,
          JSON.stringify(null)
        )
        dispatch(setAuthDataAction(null))
        track('Gift Card Purchase Submit', {
          submitResult: SUCCESS,
          couponName: giftCardData.coupon?.code ?? null,
          discountAmount: amountFormatter(
            giftCardData.amount - giftCardData.total
          ),
        })
        setOpenPaymentSuccess(true)
        dispatch(setLoadingPaymentGiftCardAction(false))
        dispatch(setLoadingPaymentAction(false))
        dispatch(setIsProcessingPaymentAction(false))
      })
    } catch (e) {
      const message =
        e?.data?.message?.[0]?.message ??
        e?.data?.message ??
        'payment.paymentError'
      const errorMessage =
        typeof message === 'string' ? message : 'payment.paymentError'
      dispatch(
        setShowAlert({
          show: true,
          type: ERROR,
          message: errorMessage.toString(),
        })
      )
      track('Gift Card Purchase Submit', {
        submitResult: FAILED,
        couponName: giftCardData?.coupon?.code ?? null,
        discountAmount: amountFormatter(
          giftCardData?.amount - giftCardData?.total || 0
        ),
      })
      dispatch(setLoadingPaymentGiftCardAction(false))
      dispatch(setIsProcessingPaymentAction(false))
    }
  }

  const redeemGiftCard = async (body, setVisible, setValue) => {
    dispatch(setLoadingRedeemAction(true))
    try {
      await post({
        path: `redeem-gift-card`,
        data: body,
      }).then(({ result }: any) => {
        track('Gift Card Redeemed', { submitResult: SUCCESS })
        setVisible(true)
        dispatch(setLoadingRedeemAction(false))
        setValue('code', null)
      })
    } catch (e) {
      track('Gift Card Redeemed', { submitResult: FAILED })
      dispatch(
        setShowAlert({
          show: true,
          type: ERROR,
          message: 'redeem.redeemError',
        })
      )
      dispatch(setLoadingRedeemAction(false))
    }
  }

  //PAYPAL METHODS
  const processGenerateOrder = async (e, data) => {
    let bookingData = data
    if (!state.isLoggedIn) {
      const userBody = setPurchaseUserBody(bookingData)
      // const { handleAutoSignUp } = useHandleUserSession(dispatch)
      // const newUser:any = await handleAutoSignUp(userBody)

      let newUser = await post({
        path: 'sign-up-auto',
        data: userBody,
      }).then(({ data }: any) => {
        const userData = mapUserData(data)
        localStorage.setItem('userPaypal', JSON.stringify(userData))
        return userData
      })

      bookingData.customerId = newUser?.id
    }
    const currentCookieAffiliateValue = getCookieValue(AFFILIATE_CODE_NAME)
    if (currentCookieAffiliateValue) {
      bookingData.affiliateCode = currentCookieAffiliateValue
    }

    const currentCookieReferentValue = getCookieValue(REFERENT_CODE_NAME)
    if (currentCookieReferentValue) {
      bookingData.referentCode = currentCookieReferentValue
    }

    const body = setPaymentBodyPaypal(bookingData, state)
    try {
      return await post({
        path: `paypal/orders`,
        data: body,
      })
        .then((response: any) => {
          return response
        })
        .then((order: any) => {
          return order.id
        })
    } catch (e) {
      let message =
        e?.data?.message?.[0]?.message ??
        e?.data?.message ??
        'payment.paymentError'

      let errorMessage =
        typeof message === 'string' ? message : 'payment.paymentError'

      const CUSTOMER_ERROR = {
        USER_DELETED: 'payment.paymentError',
        USER_BLOCKED: 'payment.userBlocked',
        USER_UNAVAILABLE: 'payment.paymentError',
      }

      if (e?.data?.errors?.errors?.[0]?.msg in CUSTOMER_ERROR) {
        errorMessage = CUSTOMER_ERROR[e?.data?.errors?.errors?.[0]?.msg]
      }

      dispatch(
        setShowAlert({
          show: true,
          type: ERROR,
          message: errorMessage?.toString(),
        })
      )
      dispatch(setLoadingPaymentAction(false))
    }
  }

  const processOnApprove = async (
    bookingData,
    data,
    setOpenPaymentSuccess,
    setLoadingCallback
  ) => {
    setLoadingCallback(true)
    const currentCookieAffiliateValue = getCookieValue(AFFILIATE_CODE_NAME)
    if (currentCookieAffiliateValue) {
      bookingData.affiliateCode = currentCookieAffiliateValue
    }

    const currentCookieReferentValue = getCookieValue(REFERENT_CODE_NAME)
    if (currentCookieReferentValue) {
      bookingData.referentCode = currentCookieReferentValue
    }

    let userPaypal
    if (!state.isLoggedIn) {
      userPaypal = JSON.parse(localStorage.getItem('userPaypal'))
      bookingData.customerId = userPaypal?.id
    }
    var body: any = setPaymentBodyPaypal(bookingData, state, data.orderID)

    dispatch(setLoadingPaymentAction(true))
    try {
      return await post({
        path: `paypal/orders/authorize-paypal-order`,
        data: body,
      })
        .then((response: any) => {
          setLoadingCallback(false)
          if (!state.isLoggedIn && userPaypal.new_user) {
            dispatch(setLoggedInAction(!!userPaypal.id))
            dispatch(setUserDataAction(userPaypal))
            saveLocalUserData(userPaypal)
            identifyUser(userPaypal)
          }
          return response
        })
        .then((order: any) => {
          dispatch(setLoadingPaymentAction(false))
          window.localStorage.setItem('localBookingData', JSON.stringify(null))
          deleteCookie(REFERENT_CODE_NAME);
          setOpenPaymentSuccess(true)
          setLoadingCallback(false)
          dispatch(setIsProcessingPaymentAction(false))
          return true
        })
    } catch (e) {
      setLoadingCallback(false)
      dispatch(setIsProcessingPaymentAction(false))
      dispatch(setLoadingPaymentAction(false))
      const message =
        e?.data?.message?.[0]?.message ??
        e?.data?.message ??
        'payment.paymentError'
      const errorMessage =
        typeof message === 'string' ? message : 'payment.paymentError'
      dispatch(
        setShowAlert({
          show: true,
          type: ERROR,
          message: errorMessage.toString(),
        })
      )
    }
  }

  const processGenerateOrderGiftCard = async () => {
    var body: any = setPaymentGiftCardBodyPaypal(state)
    try {
      return await post({
        path: `paypal/giftcard/orders`,
        data: body,
      })
        .then((response: any) => {
          return response
        })
        .then((order: any) => {
          return order.id
        })
    } catch (e) {
      const message =
        e?.data?.message?.[0]?.message ??
        e?.data?.message ??
        'payment.paymentError'
      const errorMessage =
        typeof message === 'string' ? message : 'payment.paymentError'
      dispatch(
        setShowAlert({
          show: true,
          type: ERROR,
          message: errorMessage.toString(),
        })
      )
      dispatch(setLoadingPaymentAction(false))
    }
  }

  const processOnApproveGiftCard = async (
    data,
    setOpenPaymentSuccess,
    setLoadingCallback
  ) => {
    setLoadingCallback(true)
    var body: any = setPaymentGiftCardBodyPaypal({
      isMobile: data.isMobile,
      ...state,
    })

    dispatch(setLoadingPaymentAction(true))
    return await post({
      path: `paypal/orders/giftcard/capture-paypal-order`,
      data: { ...body, orderID: data.orderID },
    })
      .then((response: any) => {
        setLoadingCallback(false)
        return response
      })
      .then((order: any) => {
        dispatch(setLoadingPaymentAction(false))
        window.localStorage.setItem(
          LOCAL_STORAGE_GIFTCARD,
          JSON.stringify(null)
        )

        setOpenPaymentSuccess(true)
        setLoadingCallback(false)
      })
  }

  return {
    getPurchases,
    cancelPurchase,
    sendReview,
    processBooking,
    processGiftCard,
    redeemGiftCard,
    processGenerateOrder,
    processOnApprove,
    processGenerateOrderGiftCard,
    processOnApproveGiftCard,
    addReview,
  }
}
