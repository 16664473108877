import { useFormatDate } from 'utils/formatDate.js'

export const setPaymentBody = (purchaseData, state) => {
  const bookingData = state?.bookingData
  const cardNumber = purchaseData?.cardNumber.replace(/-/g, '')
  let participants

  let splitDate = purchaseData?.expirationDate?.split('/')

  const hasAdditionalTransport = !!bookingData?.totalProducts?.some(
    (product) => product.additional_item?.type.code === 0
  )

  const reference = hasAdditionalTransport
    ? purchaseData.reference
    : bookingData.experience.meeting_reference || purchaseData.reference

  const pickupLocation =
    bookingData?.bookingPickupLocation || purchaseData.bookingPickupLocation

  participants = purchaseData.participants.map((participant, index) => {
    const participantData: any = {
      name: `${participant.firstName?.trim()} ${participant.lastName?.trim()}`,
      birthYear: participant.birthDate,
      dni: participant.dni,
    }

    if (index === 0) {
      participantData.email = participant.email
      participantData.phone = participant.phone
      participantData.address = participant.address
    }
    return participantData
  })

  if (purchaseData?.useForChubb === false) {
    participants = [
      {
        name: `${purchaseData.chubbFirstName?.trim()} ${purchaseData.chubbLastName?.trim()}`,
        birthYear: purchaseData.chubbBirthDate,
        dni: purchaseData.chubbDni,
        email: purchaseData.chubbEmail,
        phone: purchaseData.chubbPhone,
        address: purchaseData.chubbAddress,
      },
    ]
  }

  const body = {
    affiliate_code: purchaseData?.affiliateCode || null,
    referent_code: purchaseData?.referentCode || null,
    company: bookingData?.experience?.companies[0]?.id,
    customer: state?.user?.id || purchaseData.customerId,
    experience: bookingData?.experience?.id,
    platform: purchaseData.isMobile ? 'web-mobile' : 'web-desktop',
    quantity: bookingData?.totalParticipantQuantity,
    babies: bookingData?.totalParticipants?.babies,
    children: bookingData?.totalParticipants?.children,
    adults: bookingData?.totalParticipants?.adults,
    seniors: bookingData?.totalParticipants?.seniors,
    booking_date: `${useFormatDate(
      bookingData?.bookingDate,
      'yyyy-MM-dd'
    )} ${bookingData?.bookingHour}`,
    address: reference,
    reference: reference,
    latitude: pickupLocation?.lat,
    longitude: pickupLocation?.lng,
    additional_information: reference,
    participants: participants,
    card_data: {
      card: cardNumber,
      month: splitDate[0],
      year: splitDate[1],
      name: purchaseData?.participants[0]?.firstName?.trim(),
      lastName: purchaseData?.participants[0]?.lastName?.trim(),
      // email: purchaseData?.email,
      email: purchaseData?.participants[0]?.email,
      address: purchaseData?.participants[0]?.address,
      locality: purchaseData?.participants[0]?.address,
      city: purchaseData?.city,
      postalCode: purchaseData?.postalCode,
      country: purchaseData?.country,
      phone: purchaseData?.participants[0]?.phone,
    },
    coupon: bookingData?.coupon?.id ?? null,
    percent_discount: bookingData?.percentDiscount,
    apply_wallet: purchaseData?.apply_wallet,
    range_discount: bookingData?.rangeDiscount,
    ...(bookingData?.totalProducts.length > 0
      ? {
          additional_products: bookingData?.totalProducts.map((product) => {
            return {
              //codigo tipo 0 === transporte
              additional_data:
                product?.additional_item?.type?.code === 0
                  ? {
                      address: reference,
                      latitude: pickupLocation?.lat,
                      longitude: pickupLocation?.lng,
                      reference: reference,
                    }
                  : {},
              id: product.id,
            }
          }),
        }
      : {}),
    ...(!!bookingData?.transactionId
      ? {
          transactionId: bookingData?.transactionId,
          validateAuth: bookingData?.validateAuth ?? false,
          consumerAuthenticationInformation:
            bookingData?.consumerAuthenticationInformation,
          validatedConsumerAuthenticationInformation:
            bookingData?.validatedConsumerAuthenticationInformation,
        }
      : {}),
  }
  return body
}

export const setPaymentWithOutCreditCardBody = (purchaseData, state) => {
  const bookingData = state?.bookingData

  const hasAdditionalTransport = !!bookingData?.totalProducts?.some(
    (product) => product.additional_item?.type.code === 0
  )

  const reference = hasAdditionalTransport
    ? purchaseData.reference
    : bookingData.experience.meeting_reference || purchaseData.reference

  const participants = purchaseData.participants.map((participant, index) => {
    const participantData: any = {
      name: `${participant.firstName?.trim()} ${participant.lastName?.trim()}`,
      birthYear: participant.birthDate,
      dni: participant.dni,
    }

    if (index === 0) {
      participantData.email = participant.email
      participantData.phone = `+${participant.phone}`
      participantData.address = participant.address
    }
    return participantData
  })

  const pickupLocation =
    bookingData?.bookingPickupLocation || purchaseData.bookingPickupLocation

  const body = {
    company: bookingData?.experience?.companies[0]?.id,
    customer: state?.user?.id || purchaseData.customerId,
    experience: bookingData?.experience?.id,
    platform: purchaseData.isMobile ? 'web-mobile' : 'web-desktop',
    quantity: bookingData?.totalParticipantQuantity,
    babies: bookingData?.totalParticipants?.babies,
    children: bookingData?.totalParticipants?.children,
    adults: bookingData?.totalParticipants?.adults,
    seniors: bookingData?.totalParticipants?.seniors,
    booking_date: `${useFormatDate(
      bookingData?.bookingDate,
      'yyyy-MM-dd'
    )} ${bookingData?.bookingHour}`,
    address: reference,
    reference: reference,
    latitude: pickupLocation?.lat,
    longitude: pickupLocation?.lng,
    additional_information: reference,
    participants: participants,
    coupon: bookingData?.coupon?.id ?? null,
    apply_wallet: true, //purchaseData?.apply_wallet,
    percent_discount: bookingData?.percentDiscount,
    range_discount: bookingData?.rangeDiscount,
    ...(bookingData?.totalProducts.length > 0
      ? {
          additional_products: bookingData?.totalProducts.map((product) => {
            return {
              //codigo tipo 0 === transporte
              additional_data:
                product?.additional_item?.type?.code === 0
                  ? {
                      address: reference,
                      latitude: pickupLocation?.lat,
                      longitude: pickupLocation?.lng,
                      reference: reference,
                    }
                  : {},
              id: product.id,
            }
          }),
        }
      : {}),
  }
  return body
}

export const setPaymentGiftCardBody = (purchaseData, state) => {
  const giftCardData = state?.giftCardData
  const cardNumber = purchaseData?.cardNumber.replace(/-/g, '')

  let splitDate = purchaseData?.expirationDate?.split('/')

  const body = {
    //customer: state.user.id,
    sender_name: giftCardData?.delivery_details?.sender_user?.name,
    sender_email: giftCardData?.delivery_details?.sender_user?.email,
    beneficiary_name: giftCardData?.delivery_details?.receiver_user?.name,
    beneficiary_email: giftCardData?.delivery_details?.receiver_user?.email,
    platform: purchaseData?.isMobile ? 'web-mobile' : 'web-desktop',
    amount: giftCardData?.amount,
    message: giftCardData?.custom_message,
    delivery_date:
      giftCardData?.delivery_date === null
        ? new Date()
        : giftCardData?.delivery_date,
    coupon: giftCardData?.coupon?.id ?? null,
    celebration_type: giftCardData?.design,
    card_data: {
      name: purchaseData?.firstName?.trim(),
      lastName: purchaseData?.lastName?.trim(),
      email: purchaseData?.email,
      phone: purchaseData?.phone,
      card: cardNumber,

      month: splitDate[0],
      year: splitDate[1],
      address: purchaseData?.address,
      country: purchaseData?.country,
      city: purchaseData?.city,
      locality: purchaseData?.state,
      postalCode: purchaseData?.postalCode,
      ...(!!giftCardData?.transactionId
        ? {
            transactionId: giftCardData?.transactionId,
            validateAuth: giftCardData?.validateAuth ?? false,
            consumerAuthenticationInformation:
              giftCardData?.consumerAuthenticationInformation,
            validatedConsumerAuthenticationInformation:
              giftCardData?.validatedConsumerAuthenticationInformation,
          }
        : {}),
    },
  }
  return body
}

export const setPaymentBodyPaypal = (bookingData, state, orderID = false) => {
  const hasAdditionalTransport = !!bookingData?.totalProducts?.some(
    (product) => product.additional_item?.type.code === 0
  )

  const reference = hasAdditionalTransport
    ? bookingData?.reference
    : bookingData?.experience?.meeting_reference || bookingData?.reference

  const pickupLocation = bookingData?.bookingPickupLocation

  const participants = bookingData?.participants?.map((participant, index) => {
    const participantData: any = {
      name: `${participant.firstName?.trim()} ${participant.lastName?.trim()}`,
      birthYear: participant.birthDate,
      dni: participant.dni,
    }

    if (index === 0) {
      participantData.email = participant.email
      participantData.phone = participant.phone
      participantData.address = participant.address
    }
    return participantData
  })

  var body: any = {
    affiliate_code: bookingData?.affiliateCode || null,
    referent_code: bookingData?.referentCode || null,
    additional_information: reference,
    address: reference,
    adults: bookingData?.totalParticipants?.adults,
    apply_wallet: bookingData?.apply_wallet,
    babies: bookingData?.totalParticipants?.babies,
    booking_date: `${useFormatDate(
      bookingData?.bookingDate,
      'yyyy-MM-dd'
    )} ${bookingData?.bookingHour}`,
    children: bookingData?.totalParticipants?.children,
    company: bookingData?.experience?.companies[0]?.id,
    coupon: bookingData?.coupon?.id ?? null,
    customer: state?.user?.id || bookingData.customerId,
    experience: bookingData?.experience?.id,
    platform: bookingData.isMobile ? 'web-mobile' : 'web-desktop',
    latitude: pickupLocation?.lat,
    longitude: pickupLocation?.lng,
    quantity: bookingData?.totalParticipantQuantity,
    reference: reference,
    seniors: bookingData?.totalParticipants?.seniors,
    totalPrice: bookingData?.totalPrice,
    participants: participants,
    percent_discount: bookingData?.percentDiscount,
    range_discount: bookingData?.rangeDiscount,
    ...(bookingData?.totalProducts?.length > 0
      ? {
          additional_products: bookingData?.totalProducts.map((product) => {
            return {
              //codigo tipo 0 === transporte
              additional_data:
                product?.additional_item?.type?.code === 0
                  ? {
                      address: reference,
                      latitude: pickupLocation?.lat,
                      longitude: pickupLocation?.lng,
                      reference: reference,
                    }
                  : {},
              id: product.id,
            }
          }),
        }
      : {}),
  }
  if (orderID) {
    body = { ...body, orderID }
  }
  return JSON.stringify(body)
}
export const setPaymentGiftCardBodyPaypal = (state) => {
  const giftCardData = state?.giftCardData

  const body = {
    //customer: state.user.id,
    sender_name: giftCardData?.delivery_details?.sender_user?.name,
    sender_email: giftCardData?.delivery_details?.sender_user?.email,
    beneficiary_name: giftCardData?.delivery_details?.receiver_user?.name,
    beneficiary_email: giftCardData?.delivery_details?.receiver_user?.email,
    platform: giftCardData?.isMobile ? 'web-mobile' : 'web-desktop',
    amount: giftCardData?.amount,
    message: giftCardData?.custom_message,
    delivery_date:
      giftCardData?.delivery_date === null
        ? new Date()
        : giftCardData?.delivery_date,
    coupon: giftCardData?.coupon?.id ?? null,
    celebration_type: giftCardData?.design,
  }
  return body
}
