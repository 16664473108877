import { ArrowBlackImg } from '@/components/ArrowBlackImg'
import { Layout } from '@/components/Layout'
import { Button } from '@nextui-org/react'
import { useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import redeemImg from '@/assets/images/giftcard/redeem.png'
import { amountFormatter } from '@/utils/amountFormatter'
import arrowBlack from '@/assets/svgs/arrowBlack.svg'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'

const BalancePage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const token = window?.localStorage.getItem('token')
  const {
    hooks: { getBalance },
    state: { user, isLoggedIn, locale },
  } = useGotuuriContext()
  
  useEffect(() => {
    if (!user && !isLoggedIn && !token) {
      navigate('/')
    }
    if(user?.id){
      getBalance(user)
    }
    
  }, [isLoggedIn])
  return (
    <Layout>
      <div className="mt-12 pb-8 mb-20">
        <div className="flex gap-16 flex-col md:flex-row hidden md:block">
          <div className="md:w-12/12 mb-4 md:mb-0">
            <Button
              isIconOnly
              className="w-12 h-12 min-w-unit-0 rounded-full shadow-lg my-12"
              onPress={() => {
                navigate('/profile/credit-giftcard')
              }}
            >
              <ArrowBlackImg />
            </Button>
          </div>
        </div>
        <div className="flex gap-5 flex-col md:flex-row">
          <div className="w-12/12 md:w-7/12">
            <div className="flex gap-6 mb-7 md:hidden">
              <Link className="text-[10px]" to={'/profile'}>
                {t('profile.profile')}
              </Link>
              <img
                className="rotate-270"
                src={arrowBlack}
                alt="arrow black svg"
              />
              <Link className="text-[10px]" to={'/profile/credit-giftcard'}>
                {t('profile.creditAndGiftCard').slice(0, 17) + '...'}
              </Link>
              <img
                className="rotate-270"
                src={arrowBlack}
                alt="arrow black svg"
              />
              <p className="text-[10px] text-primary">
                {t('balance.title')}
              </p>
            </div>
            <h4 className="grid text-4xl mb-8 ">{t('balance.title')}</h4>
            <h3 className="text-5xl mb-8">{amountFormatter(user?.balance)}</h3>
            <p className="text-[10px]">{t('balance.info')}</p>
          </div>
          <div className="w-5/12 hidden md:block">
            <img src={redeemImg} alt="redeem img" />
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default BalancePage
