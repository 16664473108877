import { ArrowLeftIcon, ArrowRightIcon } from '@/assets/icons'
import { CategoryInterface } from '@/types/category'
import { useIsMobile } from '@/utils/mobile'
import { normalizeItem } from '@/utils/normalizeUrl'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import { memo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useListenLocalStorage } from '@/hooks/useListenLocalStorage'
import { COUNTRY_ID } from '@/constants/cookie'

const CarouselCategories = ({
  data,
  className,
}: {
  data: CategoryInterface[]
  className?: string
}) => {
  const { countryId } = useListenLocalStorage([COUNTRY_ID])
  const [loaded, setLoaded] = useState(false)
  const isMobile = useIsMobile()

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: !isMobile,
    mode: isMobile ? 'free-snap' : 'snap',
    breakpoints: {
      '(min-width: 480px)': {
        slides: { perView: 'auto', spacing: 15 },
      },
      '(min-width: 766px)': {
        slides: { perView: 2, spacing: 10 },
      },
      '(min-width: 1024px)': {
        slides: { perView: 3, spacing: 15 },
      },
      '(min-width: 1440px)': {
        slides: { perView: 4, spacing: 15 },
      },
    },
    slides: { perView: isMobile ? 'auto' : 4, spacing: 15 },
    initial: 0,
    created() {
      setLoaded(true)
    },
  })

  useEffect(() => {
    if (!loaded && !data?.length) return
    instanceRef.current?.update()
  }, [loaded, data])

  return (
    <div className={`navigation-wrapper ${className} relative`}>
      <div ref={sliderRef} className="keen-slider h-16">
        {data?.map((item, index) => (
          <Link
            key={index}
            className="keen-slider__slide min-w-fit max-w-fit"
            to={normalizeItem({
              ...item,
              type: 'categories',
              countryId,
            })}
          >
            <div
              key={index}
              className={`flex h-auto md:h-14 rounded-2xl p-3 md:p-2 gotuuri-filter hover:text-primary shadow-md items-center cursor-pointer my-1`}
            >
              {!isMobile && (
                <img
                  className="hidden md:block object-cover h-8"
                  src={item.icon}
                  width={32}
                  height={32}
                  alt={`${item.name} icon svg`}
                />
              )}
              <h2 className="text-sm md:text-sm 2xl:text-base text-center mx-auto select-none truncate">
                {item.name}
              </h2>
            </div>
          </Link>
        ))}
      </div>
      <ArrowLeftIcon
        onClick={(e: any) => {
          e.stopPropagation() ||
            (instanceRef?.current && instanceRef.current?.prev())
        }}
        className="h-10 w-10 absolute left-0 top-7 text-lightGray rounded-full shadow-md p-3 !bg-white z-10 cursor-pointer hidden md:block"
      />
      <ArrowRightIcon
        onClick={(e: any) =>
          e.stopPropagation() ||
          (instanceRef?.current && instanceRef.current?.next())
        }
        className="h-10 w-10 absolute -right-12 top-7 text-lightGray rounded-full shadow-md p-3 !bg-white z-10 cursor-pointer hidden md:block"
      />
    </div>
  )
}

export default CarouselCategories
