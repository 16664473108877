import { COUNTRY_ID, COUNTRY_PARAM } from '@/constants/cookie'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { getCountryLocaleString } from '@/utils/generalTitle.Util'
import { normalizeFilter } from '@/utils/normalizeUrl'
import { updateCountryStorage, updateLocation } from '@/utils/selectCountryUtil'
import { Button, Select, SelectItem } from '@nextui-org/react'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

interface SearchBarProps {
  showSubmitButton?: boolean
  showFilterIcon?: boolean
  showFilterText?: boolean
  callBack: ({ filterUrl }) => void
  pathType?: string
}
const SearchBar2 = ({ callBack }: SearchBarProps) => {
  let location = useLocation().pathname
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const {
    hooks: { getCountries, setFilterData },
    state: { filterData, countries },
  } = useGotuuriContext()

  const { t } = useTranslation()

  useEffect(() => {
    !!!countries && getCountries()
  }, [])

  const { register, handleSubmit } = useForm({
    values: { searchText: filterData?.urlParams?.searchText ?? '' },
    mode: 'onSubmit',
  })

  const onSelectionChange = async (countryId) => {
    countryId = countryId.target.value !== '' ? countryId.target.value : 'all'

    updateCountryStorage(countryId)
    const locationPathname = window.location.pathname
    if (location.includes('/country/')) {
      updateLocation(countryId, countries, navigate)
    } else {
      searchParams.has(COUNTRY_PARAM)
        ? searchParams.set(COUNTRY_PARAM, countryId)
        : searchParams.append(COUNTRY_PARAM, countryId)

      countryId === 'all' && searchParams.delete('country')
      window.history.replaceState(
        {},
        '',
        `${locationPathname}?${searchParams.toString()}`
      )

      window.location.reload()
    }
  }
  const onSubmit: SubmitHandler<{ searchText: string }> = (data) => {
    const newFilterData = {
      ...filterData,
      urlParams: {
        ...filterData?.urlParams,
        searchText: data.searchText,
        country: localStorage.getItem(COUNTRY_ID),
      },
    }
    setFilterData(newFilterData)
    const filterUrl = normalizeFilter(newFilterData.urlParams)
    callBack({ filterUrl })
  }

  const allCountries = countries?.data
    ? [
        { id: 'all', name: t('navbar.destinations'), code: 'global' },
        ...countries.data,
      ]
    : []

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex bg-white rounded-full py-0.5 px-2 shadow-md text-black w-full"
    >
      <Select
        className="max-w-xs border-gray-50 pt-0 mt-2 h-9 w-1/2"
        radius="full"
        variant="bordered"
        defaultSelectedKeys={
          localStorage.getItem(COUNTRY_ID)
            ? [localStorage.getItem(COUNTRY_ID)]
            : ['all']
        }
        placeholder={t('searchBar.selectDestination')}
        items={allCountries}
        onChange={onSelectionChange}
        disallowEmptySelection={true}
      >
        {(item: any) => (
          <SelectItem key={item.id}>
            {item.id === 'all'
              ? item.name
              : t(getCountryLocaleString(item.iso_code))}
          </SelectItem>
        )}
      </Select>
      <input
        {...register('searchText')}
        type="text"
        maxLength={35}
        className="w-full focus:outline-none py-2.5 px-4 text-lg"
        placeholder={t('searchBar.lookingFor')}
      />
      <Button
        className="!bg-primary my-0.5 md:my-1 rounded-full w-56 text-white text-lg px-4"
        type="submit"
      >
        {t('searchBar.search')}
      </Button>
    </form>
  )
}

export default SearchBar2
