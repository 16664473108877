import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { Button, Divider } from '@nextui-org/react'
import { useEffect } from 'react'
import { Accordion } from 'components/Accordion'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ArrowBlackImg } from '@/components/ArrowBlackImg'
import { googleTrack, track } from 'utils/analytics'
import { ClockIcon } from '@/assets/icons'

interface SelectHourRow {
  bookingDate: string
  handleSelectHour: (hour, index) => void
  bookingHour: string
  setBookingHour: (bookingHour) => void
}

const SelectHourRow = ({
  bookingDate,
  handleSelectHour,
  bookingHour,
  setBookingHour,
}: SelectHourRow) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const {
    state: { availableHours, bookingData, companySchedule },
  } = useGotuuriContext()

  useEffect(() => {
    if (!!bookingData && bookingData?.experience?.id === id) {
      handleSelectHour(
        bookingDate === bookingData?.bookingDate
          ? bookingData?.bookingHour
          : null,
        bookingDate === bookingData?.bookingDate
          ? availableHours?.findIndex(
              (availableHour) =>
                availableHour.hour === bookingData?.bookingHour || null
            )
          : null
      )
    }

    if (!!!bookingHour && availableHours?.length > 0) {
      handleSelectHour(availableHours[0]?.hour, 0)
    }
  }, [bookingData, availableHours, bookingDate])

  const buttonStyle = (isSelected) => {
    return `border-1  px-2 hover:!bg-primary hover:text-white hover:!border-primary justify-center !w-fit !min-w-fit ${isSelected ? 'border-primary !bg-primary text-white' : 'border-gray-700 bg-white text-gray-700'}`
  }

  return (
    <>
      <Accordion
        isOpen={!!bookingDate}
        header={(isOpen) => (
          <div
            className="flex flex-row w-full select-none cursor-pointer"
            role="button"
            onClick={() =>
              !isOpen &&
              track('Experience Time Slot View', {
                Availability: availableHours?.length > 0 ? 'yes' : 'no',
              })
            }
          >
            <ClockIcon className="w-5 h-5 mr-4 my-auto" />
            <h3 className="text-base text-black text-center truncate">
              {bookingHour || t('bookingCard.selectTime')}
            </h3>
            <ArrowBlackImg
              className={`ml-auto ${isOpen ? 'rotate-180' : ''}`}
            />
          </div>
        )}
      >
        {(onClose) => (
          <div className={'flex flex-wrap gap-1 gap-y-2 my-4'}>
            {availableHours && availableHours.length > 0 ? (
              availableHours.map((schedule, index) => (
                <Button
                  onClick={() => {
                    track('Experience Time Slot Select')
                    googleTrack('ExperienceTimeSelect')
                    setBookingHour(schedule.hour)
                    handleSelectHour(schedule.hour, index)
                    onClose()
                  }}
                  className={buttonStyle(schedule.hour === bookingHour)}
                  key={index}
                  size="sm"
                  radius="md"
                  variant="light"
                >
                  {schedule.hour}
                </Button>
              ))
            ) : (
              <Button isDisabled className={buttonStyle(false)}>
                {availableHours || !!!companySchedule
                  ? t('bookingCard.noHoursAvailable')
                  : t('bookingCard.firstSelectDate')}
              </Button>
            )}
          </div>
        )}
      </Accordion>
      <Divider className="mb-8 mt-2" />
    </>
  )
}

export default SelectHourRow
