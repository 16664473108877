import {
  Button,
  Checkbox,
  CheckboxGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ScrollShadow,
  Select,
  SelectItem,
  cn,
} from '@nextui-org/react'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { useTranslation } from 'react-i18next'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { CustomCategoryCheckBox } from './CustomCategoryCheckBox'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useIsMobile } from '@/utils/mobile'
import { normalizeFilter } from '@/utils/normalizeUrl'
import { CustomSlider } from '../CustomSlider'
import { Countries } from '@/models/country'
import arrowBlack from 'assets/svgs/arrowBlack.svg'
import { FILTER } from '@/constants/form'
import { initialForm } from '@/models/forms'
import { ExperienceType } from '@/constants/generalCarousel'

interface FilterView {
  isOpen: boolean
  onOpenChange: (value: boolean) => void
  pathType?: string
  defaultValue?: keyof initialForm
}

const FilterView = ({
  isOpen,
  onOpenChange,
  pathType = '',
  defaultValue,
}: FilterView) => {
  const {
    state: { citiesByCountry, categories, filterData, countries },
    hooks: {
      getCountries,
      getCitiesByCountry,
      deleteCitiesByCountry,
      getCategories,
    },
  }: {
    state: {
      citiesByCountry: any
      categories: any
      countries: Countries
      filterData: { urlParams: initialForm; filtersCount: number }
    }
    hooks: {
      getCountries: any
      getCitiesByCountry: any
      deleteCitiesByCountry: any
      getCategories: any
    }
  } = useGotuuriContext()

  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const { id } = useParams()
  const [citiesToShow, setCitiesToShow] = useState(isMobile ? 5 : 9)

  useEffect(() => {
    !!!countries && getCountries()
    !!!categories && getCategories()
  }, [])

  const urlParams = filterData?.urlParams

  const isArray: any = (value) => {
    if (Array.isArray(value)) {
      return value
    }
    if (!!value && !Array.isArray(value)) {
      return [value]
    }
    return []
  }

  const noFilterApply: any = {
    country: '',
    cities: [],
    categories: [],
    rangePrice: [FILTER.MINIMUM_PRICE, FILTER.MAXIMUM_PRICE],
    properties: [],
    language: [],
    duration: [FILTER.MINIMUM_DURATION, FILTER.MAXIMUM_DURATION],
    searchText: '',
  }

  const initialForm: initialForm = {
    country: urlParams?.country || '',
    cities: isArray(urlParams?.cities),
    categories: isArray(urlParams?.categories),
    rangePrice: urlParams?.rangePrice || [
      FILTER.MINIMUM_PRICE,
      FILTER.MAXIMUM_PRICE,
    ],
    properties: isArray(urlParams?.properties),
    language: isArray(urlParams?.language),
    duration: urlParams?.duration || [
      FILTER.MINIMUM_DURATION,
      FILTER.MAXIMUM_DURATION,
    ],
    searchText: urlParams?.searchText || '',
  }

  const { register, handleSubmit, control, setValue, reset, getValues } =
    useForm({
      values: initialForm,
      mode: 'onChange',
    })

  const getDefaultValue = () => {
    if (initialForm.hasOwnProperty(defaultValue)) {
      if (Array.isArray(initialForm[defaultValue])) {
        setValue(defaultValue, [id])
      } else {
        setValue(defaultValue, id)
      }
    }
  }

  const onSubmit: SubmitHandler<initialForm> = async (data) => {
    data.searchText = urlParams?.searchText
    const filterUrl = normalizeFilter(data)
    getDefaultValue()
    !!!pathType ? navigate(`/search?${filterUrl}`) : navigate(`?${filterUrl}`)
  }
  const handleChangeCountry = (id: string) => {
    getCitiesByCountry({ countryId: id })
    setValue('cities', [])
    setCitiesToShow(isMobile ? 5 : 9)
  }

  const checkBoxStyle = {
    wrapper: cn(
      'group-data-[selected=true]:after:opacity-0',
      'group-data-[selected=true]:before:bg-primary',
      'group-data-[focus-visible=true]:ring-offset-0'
    ),
  }

  useEffect(() => {
    getDefaultValue()
    if (!!getValues().country && !!!citiesByCountry) {
      getCitiesByCountry({ countryId: getValues().country })
    }
  }, [id, countries])

  useEffect(() => {
    if (!getValues().country && !!citiesByCountry) {
      deleteCitiesByCountry()
    }
  }, [isOpen])

  const titleStyle = 'text-2xl mt-8 md:mt-6 mb-4 md:mb-3'

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      scrollBehavior={'inside'}
      size={isMobile ? '2xl' : '3xl'}
      className="mx-0 md:!max-h-[90vh] md:h-auto "
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className=" shadow-medium md:shadow-none border-b-1 h-14">
              <div className="w-full">
                <h3 className="text-center text-black font-medium block text-lg md:text-2xl ">
                  {t('filter.filters')}
                </h3>
              </div>
            </ModalHeader>
            <ScrollShadow size={20} className="overscroll-none">
              <ModalBody className="scrollbar-hide rounded-3xl md:px-12">
                <form
                  id="filterForm"
                  action=""
                  className="mb-8"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div
                    className={`${pathType === ExperienceType.COUNTRY || pathType === ExperienceType.DESTINATIONS ? 'hidden' : 'block'}`}
                  >
                    <h3 className={titleStyle}>{t('filter.country')}</h3>
                    <Controller
                      name="country"
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <Select
                            {...register('country')}
                            variant="bordered"
                            label=" "
                            size="lg"
                            disallowEmptySelection
                            items={countries?.data || []}
                            selectedKeys={!!value ? [value] : []}
                            onSelectionChange={(value: any) => {
                              handleChangeCountry(value.currentKey)
                              onChange(value.currentKey)
                            }}
                            className="!mt-0"
                            labelPlacement="outside"
                            placeholder={t('filter.whereAreWeGoing')}
                            radius="full"
                          >
                            {(country: any) => (
                              <SelectItem key={country.id}>
                                {country.name}
                              </SelectItem>
                            )}
                          </Select>
                        )
                      }}
                    />
                  </div>
                  <div
                    className={`${pathType === ExperienceType.DESTINATIONS ? 'hidden' : 'block'}`}
                  >
                    {!!citiesByCountry?.length && (
                      <>
                        <h3 className={titleStyle}>
                          {t('filter.destination')}
                        </h3>
                        <Controller
                          name="cities"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <CheckboxGroup onChange={onChange} value={value}>
                              <div className="grid grid-cols-1 md:grid-cols-3 gap-y-4">
                                {Array.isArray(citiesByCountry) &&
                                  citiesByCountry
                                    .slice(
                                      0,
                                      citiesByCountry.length > citiesToShow
                                        ? citiesToShow
                                        : citiesByCountry.length
                                    )
                                    .map((city) => (
                                      <Checkbox
                                        key={city.id}
                                        classNames={checkBoxStyle}
                                        value={city.id}
                                      >
                                        {city.name}
                                      </Checkbox>
                                    ))}
                              </div>
                            </CheckboxGroup>
                          )}
                        />
                      </>
                    )}
                    {citiesByCountry?.length > citiesToShow && (
                      <div
                        className={`flex gap-1 items-center mt-4 gotuuri-general-filter cursor-pointer`}
                        onClick={() => setCitiesToShow(citiesByCountry.length)}
                      >
                        <p className="text-sm truncate ">
                          {t('experience.viewMore')}
                        </p>
                        <img
                          className={`w-2.5 rotate-270 `}
                          src={arrowBlack}
                          alt="arrow black svg"
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className={`${pathType === ExperienceType.CATEGORIES ? 'hidden' : 'block'}`}
                  >
                    <h3 className={titleStyle}>{t('filter.categories')}</h3>
                    <Controller
                      name="categories"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <CheckboxGroup onChange={onChange} value={value}>
                          <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 h-64 md:h-36">
                            {Array.isArray(categories) &&
                              categories.map((category) => (
                                <div
                                  className="flex text-center justify-center"
                                  key={category.id}
                                >
                                  <CustomCategoryCheckBox
                                    icon={category.icon}
                                    value={category.id}
                                  >
                                    {category.name}
                                  </CustomCategoryCheckBox>
                                </div>
                              ))}
                          </div>
                        </CheckboxGroup>
                      )}
                    />
                  </div>
                  <h3 className={`${titleStyle} !mb-8`}>
                    {t('filter.rangePrice')}
                  </h3>
                  <Controller
                    name="rangePrice"
                    control={control}
                    defaultValue={[0, 500]}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <CustomSlider
                          value={value as Array<number>}
                          onChange={onChange}
                          formatValue="currency"
                          min={FILTER.MINIMUM_PRICE}
                          max={FILTER.MAXIMUM_PRICE}
                          step={10}
                        />
                      )
                    }}
                  />
                  <h3 className={`${titleStyle}  !mt-12`}>
                    {t('filter.properties')}
                  </h3>
                  <Controller
                    name="properties"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CheckboxGroup onChange={onChange} value={value}>
                        <div className="grid grid-cols-2 gap-y-4 gap-x-14 w-fit">
                          <Checkbox
                            classNames={checkBoxStyle}
                            value="isPrivate"
                          >
                            {t('filter.privateTours')}
                          </Checkbox>
                          <Checkbox
                            classNames={checkBoxStyle}
                            value="isGroupal"
                          >
                            {t('filter.groupTours')}
                          </Checkbox>
                          <Checkbox
                            classNames={checkBoxStyle}
                            value="ratingInt"
                          >
                            {t('filter.ratingInt')}
                          </Checkbox>
                          <Checkbox
                            classNames={checkBoxStyle}
                            value="includeTransport"
                          >
                            {t('filter.includeTransport')}
                          </Checkbox>
                          <Checkbox
                            classNames={checkBoxStyle}
                            value="includeChildren"
                          >
                            {t('filter.acceptsChildren')}
                          </Checkbox>
                        </div>
                      </CheckboxGroup>
                    )}
                  />
                  <h3 className={titleStyle}>{t('filter.language')}</h3>
                  <Controller
                    name="language"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CheckboxGroup onChange={onChange} value={value}>
                        <div className="grid grid-cols-2 gap-y-4 gap-x-14 w-fit">
                          <Checkbox classNames={checkBoxStyle} value="spanish">
                            {t('filter.spanish')}
                          </Checkbox>
                          <Checkbox classNames={checkBoxStyle} value="english">
                            {t('filter.english')}
                          </Checkbox>
                          <Checkbox classNames={checkBoxStyle} value="german">
                            {t('filter.german')}
                          </Checkbox>
                          <Checkbox classNames={checkBoxStyle} value="french">
                            {t('filter.french')}
                          </Checkbox>
                        </div>
                      </CheckboxGroup>
                    )}
                  />
                  <h3 className={`${titleStyle} !mb-8`}>
                    {t('filter.duration')}
                  </h3>
                  <Controller
                    name="duration"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <CustomSlider
                          value={value as Array<number>}
                          onChange={onChange}
                          formatValue="time"
                          min={FILTER.MINIMUM_DURATION}
                          max={FILTER.MAXIMUM_DURATION}
                          step={1}
                        />
                      )
                    }}
                  />
                </form>
              </ModalBody>
            </ScrollShadow>
            <ModalFooter className="rounded-b-xl gap-12 shadow-inner md:shadow-none h-20 items-end border-t-1">
              <Button
                onPress={() => {
                  reset(noFilterApply)
                  if (pathType !== ExperienceType.COUNTRY)
                    deleteCitiesByCountry()
                }}
                className="text-black w-full md:w-36 rounded-full text-base h-12"
              >
                {t('filter.deleteFilters')}
              </Button>
              <Button
                type="submit"
                form="filterForm"
                className="!bg-black text-white border-1 border-white rounded-full w-full md:w-36 text-base h-12"
                onClick={onClose}
              >
                {t('filter.applyFilter')}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default FilterView
