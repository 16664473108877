import { AccordionItem, Accordion as AccordionNextui } from '@nextui-org/react'
import { ReactNode, useEffect, useState } from 'react'

interface AccordionProps {
  header: ((isOpen: boolean) => ReactNode) | ReactNode
  children: ((isOpen: () => void) => ReactNode) | ReactNode
  disabled?: boolean
  isOpen?: boolean
  setIsopen?: (isOpen: boolean) => void
}

const Accordion = ({
  header,
  children,
  disabled = false,
  isOpen = false,
  setIsopen = () => {},
}: AccordionProps) => {
  const [selectedKeys, setSelectedKeys] = useState<any>(
    isOpen ? new Set(['1']) : new Set()
  )

  useEffect(() => {
    if (!isOpen) {
      setSelectedKeys(new Set())
    } else {
      setSelectedKeys(new Set(['1']))
    }
  }, [isOpen])

  const handleSetIsOpen = (e) => {
    setSelectedKeys(e)
    if (!setIsopen) return
    if (e.has('1')) {
      setIsopen(true)
    } else {
      setIsopen(false)
    }
  }

  return (
    <AccordionNextui
      disableAnimation
      selectedKeys={selectedKeys}
      onSelectionChange={handleSetIsOpen}
      hideIndicator
      itemClasses={{
        trigger: 'p-0 mb-3',
        content: 'p-0 !-mt-3',
      }}
    >
      <AccordionItem
        key="1"
        aria-label="Accordion 1"
        className="-mx-2 overflow-hidden"
        title={
          <>
            {typeof header === 'function'
              ? header(selectedKeys?.has('1'))
              : header}
          </>
        }
      >
        {typeof children === 'function'
          ? children(() => handleSetIsOpen(new Set()))
          : children}
      </AccordionItem>
    </AccordionNextui>
  )
}

export default Accordion
