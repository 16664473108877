import { Layout } from "@/components/Layout"
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'


const NotFoundPage = () => {
    const { t } = useTranslation()

    return (
        <Layout>
            <div className="font-['Questrial'] mt-12 pb-12 px-8">
                <div className="sm:flex-row md:flex-row lg:max-xl:flex-row">
                    <h1 className="grid font-bold text-5xl mb-2">
                        Error 404
                    </h1>
                    <h2 className="text-2xl">
                        {t('notFound.title')}
                    </h2>
                    <div className="flex flex-col items-center justify-center mt-24">
                        <h1 className="grid font-bold text-5xl mb-3">
                            {t('notFound.oops')}
                        </h1>
                        <h2 className="text-2xl text-center mb-3">
                            {t('notFound.sorry')}
                        </h2>
                        <p className="text-zinc-600 text-center" dangerouslySetInnerHTML={{__html: t('notFound.message')}}></p>
                        <p className="text-primary text-center"><Link to='/'>{t('notFound.homepage')}</Link></p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default NotFoundPage